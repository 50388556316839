import React from "react";

import SEO from "components/utils/SEO";
import BlogsView from "components/views/Blogs";
import Container from "components/atoms/Container";

import blogs from "utils/blogs.json";

const BlogPage = () => (
  <Container top="sm" right="lg" left="lg">
    <SEO
      title="Blog"
      keywords={["Programming blog", "Algorithms", "Problem solving"]}
      description={`Blogs that I have written during my career - ${blogs[0].title}`}
    />

    <BlogsView blogs={blogs} />
  </Container>
);

export default BlogPage;
