import styled from "styled-components";
import { switchProp } from "styled-tools";

const sizes = {
  xs: "1vw",
  sm: "2vw",
  md: "3vw",
  lg: "4vw",
  xl: "5vw",
};

export const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${switchProp("size.top", sizes)};
  padding-right: ${switchProp("size.right", sizes)};
  padding-bottom: ${switchProp("size.bottom", sizes)};
  padding-left: ${switchProp("size.left", sizes)};
`;
