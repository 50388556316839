import React from "react";
import PropTypes from "prop-types";

import Text from "components/atoms/Text";
import Link from "components/atoms/Link";

import { Blogs, BlogItem, Image, Date, Title, Description } from "./styles";

const BlogsView = ({ blogs }) => (
  <Blogs>
    {blogs.map(blog => (
      <BlogItem key={blog.date}>
        <Link href={blog.url} local={false}>
          <Image style={{ backgroundImage: `url(${blog.image})` }}>
            <Title>
              <Text>{blog.title}</Text>
            </Title>

            <Date>
              <Text>{blog.date}</Text>
            </Date>
          </Image>

          <Description>
            <Text variant="p">{blog.description}</Text>
          </Description>
        </Link>
      </BlogItem>
    ))}
  </Blogs>
);

BlogsView.propTypes = {
  blogs: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default BlogsView;
