import React from "react";
import PropTypes from "prop-types";

import variables from "styles/variables";

import { StyledContainer } from "./styles";

const Container = ({ children, top, right, left, bottom }) => (
  <StyledContainer size={{ top, right, left, bottom }}>{children}</StyledContainer>
);

const size = PropTypes.oneOf(Object.keys(variables.media));
Container.propTypes = {
  children: PropTypes.node.isRequired,
  top: size,
  right: size,
  left: size,
  bottom: size,
};

export default Container;
