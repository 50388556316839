import posed from "react-pose";
import styled from "styled-components";

import media from "styles/media";
import variables from "styles/variables";
import { slideIn } from "utils/common";

const blogs = posed.div({ enter: { staggerChildren: 50 } });
const item = posed.div(slideIn("y"));

export const Blogs = styled(blogs)`
  color: ${variables.colors.light};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  flex-direction: column;
  ${media.sm`flex-direction: row;`}
`;

export const BlogItem = styled(item)`
  display: flex;
  flex-direction: column;

  padding: 10px 0;
  ${media.sm`padding: 10px;`};
  ${media.sm`flex-basis: 50%;`};
  ${media.lg`flex-basis: 25%;`};
`;

export const Image = styled.div`
  height: 0;
  overflow: hidden;
  padding-top: 45.45%;
  background-size: 100% 100%;
  position: relative;
`;

export const Title = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgba(37, 41, 52, 0.9);
  padding: 10px;
  width: 100%;
`;

export const Date = styled.div`
  font-size: 12px;
  font-family: Verdana, Geneva, sans-serif;
  text-transform: uppercase;

  right: 0;
  bottom: 0;
  position: absolute;
  background-color: ${variables.colors.dark};
  padding: 5px;
  border-top-left-radius: ${variables.borderRadius}px;
`;

export const Description = styled.div`
  padding: 20px 10px;
  background-color: ${variables.colors.dark};
  border-bottom-left-radius: ${variables.borderRadius}px;
  border-bottom-right-radius: ${variables.borderRadius}px;
`;
